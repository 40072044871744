import React from "react";
import Layout from "../components/layout";
import OfficeBtn from "../components/officeBtn";
import { Link } from "gatsby";

const Hr = () => {
  return (
    <Layout>
      <div className="container">
        <h1 className="text-center mt-60 mb-30">Отдел кадров</h1>
        <OfficeBtn />
        <div className="region__manager">
          <div className="region_bg">
            <div className="region__position">
              Заместитель генерального директора АО «ЭНЕРГИЯ» по персоналу
            </div>
            <ul>
              <li className="region__fio">Куликов Александр Анатольевич</li>
              <li> — офис: +7 (812) 640-30-30, доб. 727, </li>
              <li> — сотовый: +7-906-247-30-53</li>
              <li> — e-mail: kulikov@dendor.ru</li>
            </ul>
            <div className="mt-30">
              <p>
                Открытые вакансии: <Link to="/vacancy">dendor.ru/vacancy</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Hr;
